<template>
  <div>
    <v-app-bar app clipped-left color="primary">
      <v-app-bar-nav-icon
        @click="isShowDrawer = !isShowDrawer"
        class="white--text"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="font-weight-bold white--text">
        {{ currentUserOrg }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-large
            color="white"
            v-bind="attrs"
            v-on="on"
            @click="exitToLogin"
          >
            <v-icon>mdi-exit-to-app</v-icon>
          </v-btn>
        </template>
        <span>退出登录</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer
      app
      clipped
      width="250"
      mobile-breakpoint="800"
      v-model="isShowDrawer"
    >
      <v-list nav class="mt-5">
        <div v-for="item in navDrawerList" :key="item.name">
          <v-list-item v-if="!item.children" link :to="{ name: item.name }">
            <v-list-item-icon>
              <v-icon class="ml-2">{{ item.meta.navi.iconName }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.meta.navi.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-if="item.children" :value="true">
            <template #activator>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>{{ item.meta.navi.iconName }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    item.meta.navi.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="child in item.children"
              :key="child.name"
              link
              :to="{ name: child.name }"
            >
              <v-list-item-icon>
                <v-icon class="ml-8">{{ child.meta.navi.iconName }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  child.meta.navi.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer app fixed>
      <span d-inline-block> Copyright 2021 </span>
    </v-footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { userSignOut } from "@/api/user";

export default {
  data() {
    return {
      isShowDrawer: true
    };
  },

  computed: {
    ...mapGetters({
      userRoutes: "permission/userRoutes"
    }),
    navDrawerList() {
      let adminRoutes = this.userRoutes.find(route => route.name === "admin");
      if (adminRoutes && adminRoutes.children) {
        return adminRoutes.children.filter(
          route => route.meta.navi && route.meta.navi.title
        );
      }
      return [];
    },
    currentUserOrg() {
      var userOrg = this.$store.getters["user/userDispName"];
      var credDisp = this.$store.getters["user/userName"];
      if (credDisp) {
        userOrg += ` - ${credDisp}`;
      }
      return userOrg;
    }
  },

  methods: {
    async exitToLogin() {
      await userSignOut();
      this.$store.dispatch("user/clearLoginInfo");
      this.$store.dispatch("permission/clearUserRoutes");
      this.$router.push({ name: "login" });
    }
  },

  created() {
    // 默认跳转到第一个由权限的页面
    let firstRoute = this.navDrawerList[0];
    if (firstRoute) {
      this.$router.push({ name: firstRoute.name });
    }
  }
};
</script>
